import { render, staticRenderFns } from "./vipAuth.vue?vue&type=template&id=5be39543&scoped=true&"
import script from "./vipAuth.vue?vue&type=script&lang=js&"
export * from "./vipAuth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be39543",
  null
  
)

export default component.exports